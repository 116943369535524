<template>
    <div>
        <v-data-table
            :disable-pagination="disablePagination"
            :items-per-page="itemsPerPage"
            :hide-default-footer="disablePagination"
            :headers="headers"
            :height="SalesOrderItems.length < 2 ? '170px' : null"
            :items="SalesOrderItems"
            calculate-widths
            class="mt-3 appic-table-light specification-table"
            dense
            disable-sort
            hide-default-header
            item-key="index"
            single-select
        >
            <template v-slot:header="{ props: { headers } }">
                <tr>
                    <th class="grey lighten-3 pa-1 text-center width-1-pct">{{ headers[0].text }}</th>
                    <th class="grey lighten-3 pa-1 text-left">{{ headers[1].text }}</th>
                    <th class="grey lighten-3 pa-1 text-left">{{ headers[2].text }}</th>
                    <th class="grey lighten-3 pa-1 text-left">{{ headers[3].text }}</th>
                    <th class="grey lighten-3 pa-1 text-left width-18-pct">{{ headers[4].text }}</th>
                    <th class="grey lighten-3 pa-1 text-left">{{ headers[5].text }}</th>
                    <th class="grey lighten-3 pa-1 text-left">{{ headers[6].text }}</th>
                    <th class="grey lighten-3 pa-1 text-left">{{ headers[7].text }}</th>
                    <th class="grey lighten-3 pa-1 text-left">{{ headers[8].text }}</th>
                    <th class="grey lighten-3 pa-1 text-center width-1-pct">{{ headers[9].text }}</th>
                </tr>
            </template>
            <template v-slot:item.lineNumber="{ item }">
                <div class="text-center mt-3">{{ item.lineNumber }}</div>
            </template>
            <template v-slot:item.Stock.bundleno="{ item }">
                <div class="font-weight-bold mt-3 text-no-wrap">{{ item.Stock.bundleno }}</div>
            </template>
            <template v-slot:item.Stock.sosalesdescription_id="{ item }">
                <div class="d-flex flex-row align-center" v-if="item.type == 'default'">
                    <div style="min-width: 50px !important;">{{ $t('message.product') + ":" }}</div>
                    <v-autocomplete
                        :items=allProductGroups
                        :placeholder="$t('message.startTypingToSearch')"
                        :value="item.Stock.productgroup_id"
                        autocomplete="off"
                        class="custom pa-1 bg-lightgrey"
                        clearable
                        dense
                        hide-details="auto"
                        item-value="ProductGroup.id"
                        item-text="ProductGroup.title"
                        @input="updateSalesOrderItemRowHandle( item.index, 'Stock.productgroup_id',$event)"
                    ></v-autocomplete>
                </div>
                <div class="d-flex flex-row align-center" v-if="item.type == 'default'">
                    <div style="min-width: 50px !important;">{{ $t('message.species') + ":" }}</div>
                    <v-autocomplete
                        :items=allSpecies
                        :placeholder="$t('message.startTypingToSearch')"
                        :value="item.Stock.species_id"
                        autocomplete="off"
                        class="custom pa-1 bg-lightgrey"
                        clearable
                        dense
                        hide-details="auto"
                        item-value="Species.id"
                        item-text="Species.title"
                        return-object
                        @input="updateSalesOrderItemRowHandle( item.index, 'Stock.species_id',$event)"
                    ></v-autocomplete>
                </div>
                <div class="d-flex flex-row align-center" v-if="item.type == 'default'">
                    <div style="min-width: 50px !important;">{{ $t('message.spec') + ":" }}</div>
                    <v-text-field
                        :value="item.Stock.salesdescription"
                        autocomplete="off"
                        class="custom force-text-left pa-1 bg-lightgrey"
                        clearable
                        dense
                        hide-details="auto"
                        @change="updateSalesOrderItemRowHandle( item.index, 'Stock.salesdescription',$event)"
                    ></v-text-field>
                </div>
                <v-text-field
                    :value="item.Stock.size"
                    autocomplete="off"
                    class="custom force-text-left pa-1 bg-lightgrey"
                    clearable
                    dense
                    hide-details="auto"
                    v-if="item.type == 'othercharge'"
                    @change="updateSalesOrderItemRowHandle( item.index, 'Stock.size',$event)"
                ></v-text-field>
                <div class="d-flex flex-row align-center" v-if="item.type == 'default'">
                    <div style="min-width: 50px !important;">{{ $t('message.grade') + ":" }}</div>
                    <v-autocomplete
                        :items="allGrades"
                        :placeholder="$t('message.startTypingToSearch')"
                        :value="item.Stock.grade_id"
                        autocomplete="off"
                        class="custom pa-1 bg-lightgrey"
                        clearable
                        dense
                        hide-details="auto"
                        item-text="Grade.title"
                        item-value="Grade.id"
                        return-object
                        @focus="currentRowIndex = item.index"
                        @change="updateSalesOrderItemRowHandle( item.index, 'Stock.grade_id',$event)"
                    >
                        <template v-slot:selection="{ item }">
                            <div class="text-wrap" style="width: 100%">{{ item.Grade.title }}</div>
                        </template>
                    </v-autocomplete>
                </div>
            </template>
            <template v-slot:item.Stock.fsc="{ item }">
                <v-select
                    :items="allCertifications"
                    :value="item.Stock.fsc"
                    autocomplete="off"
                    class="custom pa-1 bg-lightgrey"
                    clearable
                    dense
                    hide-details="auto"
                    item-text="Certification.title"
                    item-value="Certification.title"
                    v-if="item.type == 'default'"
                    @change="updateSalesOrderItemRowHandle( item.index, 'Stock.fsc',$event)"
                ></v-select>
                <v-text-field
                    :value="item.Stock.fsc_description"
                    autocomplete="off"
                    class="custom force-text-left pa-1 bg-lightgrey"
                    clearable
                    dense
                    hide-details="auto"
                    v-if="item.type == 'default' && false"
                    @change="updateSalesOrderItemRowHandle( item.index, 'Stock.fsc_description',$event)"
                ></v-text-field>
            </template>
            <template v-slot:item.Stock.thick="{ item }">
                <div class="d-flex flex-row align-center" v-if="item.type == 'default'">
                    <div style="min-width: 50px !important;">{{ $t('message.thick') + ":" }}</div>
                    <v-text-field
                        :value="item.Stock.thick"
                        autocomplete="off"
                        class="custom pa-1 bg-lightgrey col-5"
                        clearable
                        dense
                        hide-details="auto"
                        type="text"
                        @change="updateSalesOrderItemRowHandle( item.index, 'Stock.thick',$event)"
                    ></v-text-field>
                    <v-select
                        :items="uofmDimensions"
                        :value="item.Stock.smeasurement_id"
                        autocomplete="off"
                        class="custom pa-1 bg-lightgrey ml-1"
                        clearable
                        dense
                        hide-details="auto"
                        item-value="Measurement.id"
                        item-text="Measurement.title"
                        @change="updateSalesOrderItemRowHandle( item.index, 'Stock.smeasurement_id',$event)"
                    ></v-select>
                </div>
                <div class="d-flex flex-row align-center" v-if="item.type == 'default'">
                    <div style="min-width: 50px !important;">{{ $t('message.width') + ":" }}</div>
                    <v-text-field
                        :value="item.Stock.width"
                        autocomplete="off"
                        class="custom pa-1 bg-lightgrey col-5"
                        clearable
                        dense
                        hide-details="auto"
                        type="text"
                        @change="updateSalesOrderItemRowHandle( item.index, 'Stock.width',$event)"
                    ></v-text-field>
                    <v-select
                        :items="uofmDimensions"
                        :value="item.Stock.wmeasurement_id"
                        autocomplete="off"
                        class="custom pa-1 bg-lightgrey ml-1"
                        clearable
                        dense
                        hide-details="auto"
                        item-value="Measurement.id"
                        item-text="Measurement.title"
                        @change="updateSalesOrderItemRowHandle( item.index, 'Stock.wmeasurement_id',$event)"
                    ></v-select>
                </div>
                <div class="d-flex flex-row align-center" v-if="item.type == 'default'">
                    <div style="min-width: 50px !important;">{{ $t('message.length') + ":" }}</div>
                    <v-text-field
                        :value="item.Stock.length"
                        autocomplete="off"
                        clearable
                        class="custom pa-1 bg-lightgrey col-5"
                        dense
                        hide-details="auto"
                        type="text"
                        @change="updateSalesOrderItemRowHandle( item.index, 'Stock.length',$event)"
                    ></v-text-field>
                    <v-select
                        :items="uofmDimensions"
                        :value="item.Stock.lmeasurement_id"
                        autocomplete="off"
                        class="custom pa-1 bg-lightgrey ml-1"
                        clearable
                        dense
                        hide-details="auto"
                        item-value="Measurement.id"
                        item-text="Measurement.title"
                        @change="updateSalesOrderItemRowHandle( item.index, 'Stock.lmeasurement_id',$event)"
                    ></v-select>
                </div>
            </template>
            <template v-slot:item.Stock.piece="{ item }">
                <div class="d-flex flex-row align-center" v-if="item.type == 'default'">
                    <div style="min-width: 50px !important;">{{ $t('message.pcs') + ":" }}</div>
                    <v-text-field
                        :value="item.Stock.piece"
                        autocomplete="off"
                        class="custom pa-1 bg-lightgrey"
                        clearable
                        dense
                        hide-details="auto"
                        type="number"
                        @change="updateSalesOrderItemRowHandle( item.index, 'Stock.piece',$event)"
                    ></v-text-field>
                </div>
                <div class="d-flex flex-row align-center" v-if="item.type == 'default'">
                    <div style="min-width: 50px !important;">{{ $t('message.qty') + ":" }}</div>
                    <v-text-field
                        :value="item.Stock.volume"
                        autocomplete="off"
                        class="custom pa-1 bg-lightgrey"
                        clearable
                        dense
                        hide-details
                        type="number"
                        @change="updateSalesOrderItemRowHandle( item.index, 'Stock.volume',$event)"
                    ></v-text-field>
                </div>
                <div class="d-flex flex-row align-center" v-if="item.type == 'default'">
                    <div style="min-width: 50px !important;">{{ $t('message.unit') + ":" }}</div>
                    <v-select
                        :items="uofmQty"
                        :value="item.Stock.soldmeasurement_id"
                        autocomplete="off"
                        class="custom pa-1 bg-lightgrey"
                        clearable
                        dense
                        hide-details="auto"
                        item-value="Measurement.id"
                        item-text="Measurement.title"
                        @change="updateSalesOrderItemRowHandle( item.index, 'Stock.soldmeasurement_id',$event)"
                    ></v-select>
                </div>
            </template>
            <template v-slot:item.Stock.costprice="{ item }">
                <div class="d-flex flex-row align-center" v-if="item.type == 'default'">
                    <div style="min-width: 50px !important;">{{ $t('message.buying') + ":" }}</div>
                    <v-text-field
                        :value="item.Stock.costprice"
                        autocomplete="off"
                        class="custom pa-1 bg-lightgrey"
                        clearable
                        dense
                        hide-details="auto"
                        type="number"
                        @change="updateSalesOrderItemRowHandle( item.index, 'Stock.costprice',$event)"
                    ></v-text-field>
                    <div class="ml-1 mt-2">{{ currency(item.Stock.StockOrder.currency_id) }}</div>
                </div>
                <div class="d-flex flex-row align-center" v-if="item.type == 'default'">
                    <div style="min-width: 50px !important;">{{ $t('message.fw') + ":" }}</div>
                    <v-text-field
                        :value="item.Stock.forwardingcharge"
                        autocomplete="off"
                        class="custom pa-1 bg-lightgrey"
                        clearable
                        dense
                        hide-details="auto"
                        type="number"
                        @change="updateSalesOrderItemRowHandle( item.index, 'Stock.forwardingcharge',$event)"
                    ></v-text-field>
                    <div class="ml-1 mt-2">{{ currency(item.Stock.StockOrder.currency_id) }}</div>
                </div>
                <div class="d-flex flex-row align-center" v-if="item.type == 'default'">
                    <div style="min-width: 50px !important;">{{ $t('message.wh') + ":" }}</div>
                    <v-text-field
                        :value="item.Stock.warehousecost"
                        autocomplete="off"
                        class="custom pa-1 bg-lightgrey"
                        clearable
                        dense
                        hide-details="auto"
                        type="number"
                        @change="updateSalesOrderItemRowHandle( item.index, 'Stock.warehousecost',$event)"
                    ></v-text-field>
                    <div class="ml-1 mt-2">{{ currency(item.Stock.StockOrder.currency_id) }}</div>
                </div>
            </template>
            <template v-slot:item.Stock.sellingprice="{ item }">
                <div class="d-flex flex-row align-center">
                    <v-text-field
                        :value="item.Stock.sellingprice"
                        autocomplete="off"
                        class="custom pa-1 bg-lightgrey"
                        clearable
                        dense
                        hide-details="auto"
                        type="number"
                        @change="updateSalesOrderItemRowHandle( item.index, 'Stock.sellingprice',$event)"
                    ></v-text-field>
                    <div class="ml-1 mt-2">{{ currency(SalesOrder__currency_id) }}</div>
                </div>
            </template>
            <template v-slot:item.Stock.amount="{ item }">
                <div class="mt-3 text-no-wrap text-right align-center" style="height: 30px">{{ formatThisNumber(item.Stock.amount, findCurrencyPrecision(SalesOrder__currency_id)) + " " + currency(SalesOrder__currency_id) }}</div>
            </template>
            <template v-slot:item.Stock.id="{ item }">
                <div class="ml-2" v-if="SalesOrder__orderstatus_id == 1 && (SalesOrder__issigned == null || SalesOrder__issigned == 0)">
                    <v-tooltip bottom>
                        <template v-slot:activator="{ on }">
                            <v-btn icon @click="deleteItem(item.index, item.Stock.id)" v-on="on">
                                <v-icon color="grey">delete</v-icon>
                            </v-btn>
                        </template>
                        <span>{{ $t('message.deleteThisItem') }}</span>
                    </v-tooltip>
                </div>
                <div v-else class="mt-3 text-center font-weight-bold align-center" style="height: 30px">{{ $t('message.na') }}</div>
            </template>
            <template v-slot:footer>
                <div class="pt-0 d-flex justify-space-between">
                    <div class="col-4 col-lg-4 col-md-3 pt-5 px-0 d-flex justify-start">
                        <v-btn
                            @click="openSelectStockDialog()"
                            color="success"
                            small
                            v-if="SalesOrder__orderstatus_id == 1 && SalesOrder__issigned == 0"
                        >{{ SalesOrderItems.length == 0 ? $t('message.addItem') : $t('message.addMore') }}
                        </v-btn>
                        <v-btn
                            @click="addOtherChargesItemRow(statePrefix)"
                            class="ml-2"
                            color="success"
                            small
                            v-if="SalesOrder__orderstatus_id == 1 && SalesOrder__issigned == 0"
                        >{{ $t('message.addOtherCharges') }}
                        </v-btn>
                        <v-btn
                            :loading="resettingSalesOrderItems"
                            class="ml-2"
                            color="default"
                            small
                            v-if="updateMode == false"
                            @click="resetItems()"
                        ><v-icon>mdi-repeat</v-icon> {{ $t('message.reset') }}
                        </v-btn>
                    </div>
                    <div class="dense-inputs col-8 col-lg-8 col-md-9 pa-0">
                        <v-row justify="end">
                            <v-col cols="1" lg="2" md="2" xs="3" class="pl-1 pr-3 pb-0">
                                <div class="text-right font-sm font-weight-black">{{ $t('message.totalPieces') }}</div>
                                <div class="text-right font-md font-weight-black">{{ itemsTotalPieces }}</div>
                            </v-col>
                            <v-col cols="2" lg="2" md="2" xs="3" class="px-1 pb-0">
                                <div class="text-right font-sm font-weight-black">{{ $t('message.totalVolAndQty') }}</div>
                                <div :class="'text-right ' + (itemsTotalQty.length > 1 ? 'font-sm' : 'font-md') + ' font-weight-black d-flex flex-column'">
                                    <span v-for="(total,index) in itemsTotalQty" :class="itemsTotalQty.length > 1 && index == 0  ? 'pt-1' : ''">
                                        {{ formatThisNumber(total.Total.qty,uofmPrecision(total.Total.uofm)) + ' '+ total.Total.uofm }}
                                    </span>
                                </div>
                            </v-col>
                            <v-col cols="1" lg="2" md="2" xs="3" class="px-1 pb-0">
                                <div class="text-right font-sm font-weight-black">{{ $t('message.currency') }}</div>
                                <v-container class="ma-0 pa-0">
                                    <v-row no-gutters>
                                        <v-col cols="9" offset="3">
                                            <v-select
                                                :items="allCurrencies"
                                                class="custom py-0 mt-0 bg-lightgrey"
                                                hide-details
                                                item-text="Currency.code"
                                                item-value="Currency.id"
                                                reverse
                                                v-model="SalesOrder__currency_id"
                                            >
                                            </v-select>
                                        </v-col>
                                    </v-row>
                                </v-container>
                            </v-col>
                            <v-col cols="1" lg="2" md="2" xs="3" class="px-1 pb-0">
                                <div class="text-right font-sm font-weight-black">
                                    <span v-if="SalesOrder__currency_id == 2">{{ allCurrencies.find(c => c.Currency.id == Office__currency_id).Currency.code + ':' + allCurrencies.find( c => c.Currency.id == 2).Currency.code + ' ' + $t('message.exRate') }}</span>
                                    <span v-else>{{ allCurrencies.find(c => c.Currency.id == SalesOrder__currency_id).Currency.code + ':' + allCurrencies.find( c => c.Currency.id == 2).Currency.code + ' ' + $t('message.exRate') }}</span>
                                </div>
                                <v-container class="ma-0 pa-0">
                                    <v-text-field
                                        :value="SalesOrder__gstrmrate"
                                        class="custom py-1 pb-2 mt-0 bg-lightgrey"
                                        dense
                                        hide-details
                                        type="number"
                                        @change="SalesOrder__gstrmrate = $event"
                                    >
                                    </v-text-field>
                                </v-container>
                            </v-col>
                            <v-col cols="1" lg="2" md="2" xs="3" class="pl-1 pr-3 pb-0">
                                <div class="text-right font-sm font-weight-black">{{ $t('message.totalAmount') + '(' + allCurrencies.find(c => c.Currency.id == 2).Currency.code +')' }}</div>
                                <div class="text-right font-md font-weight-black">
                                    <span v-if="SalesOrder__currency_id == 2">{{ formatThisNumber((itemsTotalAmount),currencyFormatPrecision) }}</span>
                                    <span v-else>{{ formatThisNumber((itemsTotalAmount / SalesOrder__gstrmrate),currencyFormatPrecision) }}</span>
                                </div>
                            </v-col>
                            <v-col cols="1" lg="2" md="2" xs="3" class="pl-1 pr-3 pb-0">
                                <div class="text-right font-sm font-weight-black">
                                    <span v-if="SalesOrder__currency_id == 2">{{ $t('message.totalAmount') + '(' + allCurrencies.find(c => c.Currency.id == Office__currency_id).Currency.code +')' }}</span>
                                    <span v-else>{{ $t('message.totalAmount') + '(' + allCurrencies.find(c => c.Currency.id == SalesOrder__currency_id).Currency.code +')' }}</span>
                                </div>
                                <div class="text-right font-md font-weight-black">
                                    <span v-if="SalesOrder__currency_id == 2">{{ formatThisNumber(itemsTotalAmount * SalesOrder__gstrmrate,currencyFormatPrecision) }}</span>
                                    <span v-else>{{ formatThisNumber(itemsTotalAmount,currencyFormatPrecision) }}</span>
                                </div>
                            </v-col>
                        </v-row>
                    </div>
                </div>
            </template>
        </v-data-table>
        <v-dialog
            persistent
            scrollable
            transition="dialog-top-transition"
            v-model="selectStockDialog"
            width="100%"
            max-width="1300px !important"
        >
            <v-card>
                <v-card-title class="success white--text">{{ $t('message.selectWarehouseStock') }}</v-card-title>
                <v-card-text class="pa-3">
                    <v-overlay
                        :value="loading.warehouseStock"
                        absolute
                        opacity="0.15"
                    >
                        <v-row>
                            <v-col class="text-center">
                                <v-progress-circular
                                    color="primary"
                                    indeterminate
                                    size="40"
                                    width="6"
                                ></v-progress-circular>
                            </v-col>
                        </v-row>
                    </v-overlay>
                    <v-data-table
                        :footer-props="{
                            itemsPerPageOptions: [10,20,50,-1],
                            showCurrentPage: true,
                            showFirstLastPage: true
                        }"
                        :headers="stockHeaders"
                        :items="WarehouseStocks"
                        :search="searchTerm"
                        :options.sync="tableOptions"
                        calculate-widths
                        class="appic-table-light specification-table"
                        dense
                        item-key="Stock.id"
                        show-select
                        v-model="allSelectedBundles"
                    >
                        <template v-slot:top>
                            <v-row no-gutters>
                                <v-col cols="6" class="d-flex flex-row col-lg-6 col-sm-12 align-center pb-3">
                                    <v-text-field
                                        :label="$t('message.filterResults')"
                                        :value="searchTerm"
                                        autocomplete="off"
                                        class="force-text-left mt-2 mb-0"
                                        dense
                                        prepend-inner-icon="mdi-filter-outline"
                                        hide-details
                                        @change="searchTerm = $event"
                                    >
                                    </v-text-field>
                                    <v-btn
                                        :loading="loading.filterResults"
                                        class="ml-3"
                                        small
                                        @click="filterResults"
                                    >{{ $t('message.filter') }}
                                    </v-btn>
                                    <v-btn
                                        class="ml-3"
                                        small
                                        @click="searchTerm = null"
                                    >
                                        <v-icon>refresh</v-icon>
                                    </v-btn>
                                </v-col>
                            </v-row>
                        </template>
                        <template v-slot:header.data-table-select="{ on, props }">
                            <v-simple-checkbox
                                v-bind="props"
                                v-on="on"
                            ></v-simple-checkbox>
                        </template>
                        <template v-slot:item.data-table-select="{ isSelected, select, item }">
                            <div class="pt-1 text-center" v-if="checkIfSelected(item.Stock.id)">
                                <del>
                                    <v-simple-checkbox
                                        :value="isSelected"
                                        @input="select($event)"
                                    ></v-simple-checkbox>
                                </del>
                            </div>
                            <div class="pt-1 text-center" v-else>
                                <v-simple-checkbox
                                    :value="isSelected"
                                    @input="select($event)"
                                ></v-simple-checkbox>
                            </div>
                        </template>
                        <template v-slot:item.Stock.warehouse="{ item }">
                            <div class="font-sm font-weight-bold pt-2 text-no-wrap" v-if="checkIfSelected(item.Stock.id)">
                                <del>{{ item.Stock.warehouse }}</del>
                            </div>
                            <div class="font-sm font-weight-bold pt-2 text-no-wrap" v-else>{{ item.Stock.warehouse }}</div>
                        </template>
                        <template v-slot:item.Stock.bundleno="{ item }">
                            <div class="font-sm font-weight-bold pt-2 text-no-wrap" v-if="checkIfSelected(item.Stock.id)">
                                <del>{{ item.Stock.bundleno }}</del>
                            </div>
                            <div class="font-sm font-weight-bold pt-2 text-no-wrap" v-else>
                                {{ item.Stock.bundleno }}
                            </div>
                        </template>
                        <template v-slot:item.Product.title="{ item }">
                            <div class="font-sm pt-2 text-no-wrap" v-if="checkIfSelected(item.Stock.id)">
                                <del>{{ item.Product.title }}</del>
                            </div>
                            <div class="font-sm pt-2 text-no-wrap" v-else>{{ item.Product.title }}</div>
                        </template>
                        <template v-slot:item.Species.title="{ item }">
                            <div class="font-sm pt-2 text-no-wrap" v-if="checkIfSelected(item.Stock.id)">
                                <del>{{ item.Species.title }}</del>
                            </div>
                            <div class="font-sm pt-2 text-no-wrap" v-else>{{ item.Species.title }}</div>
                        </template>
                        <template v-slot:item.Grade.title="{ item }">
                            <div class="font-sm pt-2 text-no-wrap" v-if="checkIfSelected(item.Stock.id)">
                                <del>{{ item.Grade.title }}</del>
                            </div>
                            <div class="font-sm pt-2 text-no-wrap" v-else>{{ item.Grade.title }}</div>
                        </template>
                        <template v-slot:item.Stock.fsc="{ item }">
                            <div class="font-sm pt-2 text-no-wrap" v-if="checkIfSelected(item.Stock.id)">
                                <del>{{ item.Stock.fsc }}</del>
                            </div>
                            <div class="font-sm pt-2 text-no-wrap" v-else>{{ item.Stock.fsc_description }}</div>
                        </template>
                        <template v-slot:item.Stock.thick="{ item }">
                            <div class="font-xs pt-2 text-no-wrap" v-if="checkIfSelected(item.Stock.id)">
                                <del>{{ formatSize(item) }}</del>
                            </div>
                            <div class="font-xs pt-2 text-no-wrap" v-else>{{ formatSize(item) }}</div>
                        </template>
                        <template v-slot:item.Stock.piece="{ item }">
                            <div class="font-sm pt-2 float-right" v-if="checkIfSelected(item.Stock.id)">
                                <del>{{ item.Stock.piece }}</del>
                            </div>
                            <div class="font-sm pt-2 float-right" v-else>{{ item.Stock.piece }}</div>
                        </template>
                        <template v-slot:item.Stock.volume="{ item }">
                            <div class="font-sm pt-2 float-right text-no-wrap" v-if="checkIfSelected(item.Stock.id)">
                                <del>{{ item.Stock.volume + " " + item.Stock.StockOrder.Quantity.uofm}}</del>
                            </div>
                            <div class="font-sm pt-2 float-right text-no-wrap" v-else>{{ item.Stock.volume + " " + item.Stock.StockOrder.Quantity.uofm}}</div>
                        </template>
                        <template v-slot:item.Stock.costprice="{ item }">
                            <div class="font-sm pt-2 float-right" v-if="checkIfSelected(item.Stock.id)">
                                <del>{{ formatThisNumber(item.Stock.costprice, findCurrencyPrecision(item.Stock.StockOrder.currency_id)) + " " + currency(item.Stock.StockOrder.currency_id) }}</del>
                            </div>
                            <div class="font-sm pt-2 float-right" v-else>{{ formatThisNumber(item.Stock.costprice, findCurrencyPrecision(item.Stock.StockOrder.currency_id)) + " " + currency(item.Stock.StockOrder.currency_id) }}</div>
                        </template>
                        <template v-slot:item.Stock.sellingprice="{ item }">
                            <div class="font-sm pt-2 float-right" v-if="checkIfSelected(item.Stock.id)">
                                <del>{{ formatThisNumber(item.Stock.sellingprice, findCurrencyPrecision(item.Stock.StockOrder.currency_id)) + " " + currency(item.Stock.StockOrder.currency_id) }}</del>
                            </div>
                            <div class="font-sm pt-2 float-right" v-else>{{ formatThisNumber(item.Stock.sellingprice, findCurrencyPrecision(item.Stock.StockOrder.currency_id)) + " " + currency(item.Stock.StockOrder.currency_id) }}</div>
                        </template>
                        <template v-slot:item.Stock.StockOrder.Supplier.code="{ item }">
                            <div class="font-sm pt-2" v-if="checkIfSelected(item.Stock.id)">
                                <del>{{ item.Stock.StockOrder.Supplier.code }}</del>
                            </div>
                            <div class="font-sm pt-2" v-else>{{ item.Stock.StockOrder.Supplier.code }}</div>
                        </template>
                        <template v-slot:item.Stock.StockOrder.container="{ item }">
                            <div class="font-sm pt-2" v-if="checkIfSelected(item.Stock.id)">
                                <del>{{ item.Stock.StockOrder.container }}</del>
                            </div>
                            <div class="font-sm pt-2" v-else>{{ item.Stock.StockOrder.container }}</div>
                        </template>
                        <template v-slot:item.Stock.StockOrder.stockindate="{ item }">
                            <div class="font-sm pt-2" v-if="checkIfSelected(item.Stock.id)">
                                <del>{{ formatDate(item.Stock.StockOrder.stockindate) }}</del>
                            </div>
                            <div class="font-sm pt-2" v-else>{{ formatDate(item.Stock.StockOrder.stockindate) }}</div>
                        </template>
                    </v-data-table>
                </v-card-text>
                <v-card-actions class="pr-3 pt-3">
                    <v-spacer></v-spacer>
                    <v-btn
                        class="ml-3"
                        color="default"
                        small
                        @click="selectStockDialog = false"
                    >{{ $t('message.backToEditing') }}
                    </v-btn>
                    <v-btn
                        :loading="loading.addBundle"
                        small
                        @click="addBundlesToSalesOrder()"
                    >{{ $t('message.addItems') }}</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import {mapFieldsPrefixed, mapMultiRowFieldsPrefixed} from "Helpers/vuex-map-fields-prefixed";
import {mapFields} from "vuex-map-fields";
import {formatDate, numberFormat} from "Helpers/helpers";
import {mapActions, mapGetters, mapMutations} from "vuex";
import { api } from "../../api";

export default {
    name: "SalesOrderItemList",
    props: ['updateMode'],
    data () {
        return {
            allSelectedBundles: [],
            certifications: [
                {text: 'FSCCW', value: 'FSCCW'},
                {text: 'FSCCOC', value: 'FSCCOC'},
                {text: 'PEFC', value: 'PEFC'}
            ],
            currentRowIndex: 0,
            dialogs: {
                confirm:  false,
                confirm_message: ""
            },
            disablePagination: true,
            itemsPerPage: -1,
            loading: {
                addBundle: false,
                warehouseStock: false,
                filterResults: false,
            },
            WarehouseStocks: [],
            resettingSalesOrderItems: false,
            searchTerm: null,
            selectStockDialog: false,
            tableOptions: {
                page: 1
            }
        }
    },
    computed: {
        statePrefix() {
            if (this.updateMode) {
                return 'update'
            } else {
                return 'current'
            }
        },
        ...mapFieldsPrefixed('salesorder', {
            SalesOrder__currency_id: 'SalesOrder.currency_id',
            SalesOrder__gstpercent: 'SalesOrder.gstpercent',
            SalesOrder__gstrmrate: 'SalesOrder.gstrmrate',
            SalesOrder__id: 'SalesOrder.id',
            SalesOrder__issigned: 'SalesOrder.issigned',
            SalesOrder__office_id: 'SalesOrder.office_id',
            SalesOrder__orderstatus_id: 'SalesOrder.orderstatus_id',
        },'statePrefix'),
        ...mapFields('salesorder',{
            newSalesOrderSaved: 'newSalesOrderSaved',
            newSalesOrderUnsaved: 'newSalesOrderUnsaved',
            updatedSalesOrderSaved: 'updatedSalesOrderSaved',
            updatedSalesOrderUnsaved: 'updatedSalesOrderUnsaved'
        }),
        ...mapMultiRowFieldsPrefixed('salesorderitem',{
            SalesOrderItems: 'SalesOrderItems',
        },'statePrefix'),
        ...mapFieldsPrefixed('salesorderitem',{
            itemsTotalAmount: 'itemsTotalAmount',
            itemsTotalPieces: 'itemsTotalPieces',
            itemsTotalQty: 'itemsTotalQty'
        },'statePrefix'),
        ...mapGetters('certification',{
            allCertifications: 'allCertifications'
        }),
        ...mapGetters('currency',{
            allCurrencies: 'allCurrencies'
        }),
        ...mapGetters('grade',{
            allGrades: 'allGrades',
            findGradeById: 'findGradeById'
        }),
        ...mapGetters('productgroup',{
            allProductGroups: 'allProductGroups'
        }),
        ...mapGetters('species', {
            allSpecies: 'allSpecies',
            findSpeciesById: 'findSpeciesById'
        }),
        ...mapGetters([
            // 'certificationsNew',
            'currencies',
            'offices',
            'productGroups',
            'uofmDimensions',
            'uofmQty'
        ]),
        currencyFormatPrecision () {
            return this.allCurrencies.find((currency) => currency.Currency.id === this.SalesOrder__currency_id)?.Currency?.formatPrecision
        },
        headers(){
            return [
                {
                    id: 0,
                    text: this.$t('message.lineNo'),
                    value: 'lineNumber'
                },
                {
                    id: 1,
                    text: this.$t('message.bundle'),
                    value: 'Stock.bundleno'
                },
                {
                    id: 2,
                    text: this.$t('message.certification'),
                    value: 'Stock.fsc'
                },
                {
                    id: 3,
                    text: this.$t('message.description'),
                    value: 'Stock.sosalesdescription_id'
                },
                {
                    id: 4,
                    text: this.$t('message.size'),
                    value: 'Stock.thick'
                },
                {
                    id: 5,
                    text: this.$t('message.quantity'),
                    class: 'force-text-right',
                    value: 'Stock.piece'
                },
                {
                    id: 6,
                    text: this.$t('message.costPrice'),
                    class: 'force-text-right',
                    value: 'Stock.costprice'
                },
                {
                    id: 7,
                    text: this.$t('message.salesPrice'),
                    class: 'force-text-right',
                    value: 'Stock.sellingprice'
                },
                {
                    id: 8,
                    text: this.$t('message.amount'),
                    class: 'force-text-right',
                    value: 'Stock.amount'
                },
                {
                    id: 9,
                    text: this.$t('message.actions'),
                    value: 'Stock.id'
                },
            ]
        },
        Office__currency_id () {
            let office = this.offices.find(o => o.Office.id == this.SalesOrder__office_id)
            return office.Office.currency_id
        },
        stockHeaders () {
            return [
                {
                    id: 1,
                    text: this.$t('message.wh'),
                    value: 'Stock.warehouse',
                    class: 'grey lighten-3 pa-1 width-1-pct',
                    sortable: false
                },
                {
                    id: 2,
                    text: this.$t('message.product'),
                    value: 'Product.title',
                    class: 'grey lighten-3 pa-1',
                    sortable: true
                },
                {
                    id: 3,
                    text: this.$t('message.bundle'),
                    value: 'Stock.bundleno',
                    class: 'grey lighten-3 pa-1 width-1-pct',
                    sortable: true
                },
                {
                    id: 4,
                    text: this.$t('message.species'),
                    value: 'Species.title',
                    class: 'grey lighten-3 pa-1 width-1-pct',
                    sortable: true
                },
                {
                    id: 5,
                    text: this.$t('message.grade'),
                    value: 'Grade.title',
                    class: 'grey lighten-3 pa-1 width-1-pct',
                    sortable: true
                },
                {
                    id: 6,
                    text: this.$t('message.certification'),
                    value: 'Stock.fsc',
                    class: 'grey lighten-3 pa-1 width-1-pct',
                    sortable: true
                },
                {
                    id: 7,
                    text: this.$t('message.size'),
                    value: 'Stock.thick',
                    class: 'grey lighten-3 pa-1 width-1-pct',
                    sortable: true
                },
                {
                    id: 8,
                    text: this.$t('message.pcs'),
                    value: 'Stock.piece',
                    class: 'grey lighten-3 pa-1 width-1-pct force-text-right',
                    sortable: false
                },
                {
                    id: 9,
                    text: this.$t('message.quantity'),
                    value: 'Stock.volume',
                    class: 'grey lighten-3 pa-1 width-1-pct text-no-wrap force-text-right',
                    sortable: false
                },
                {
                    id: 10,
                    text: this.$t('message.costPrice'),
                    value: 'Stock.costprice',
                    class: 'grey lighten-3 pa-1 text-no-wrap force-text-right',
                    sortable: false
                },
                {
                    id: 11,
                    text: this.$t('message.sellingPrice'),
                    value: 'Stock.sellingprice',
                    class: 'grey lighten-3 pa-1 text-no-wrap force-text-right',
                    sortable: false
                },
                {
                    id: 12,
                    text: this.$t('message.supplier'),
                    value: 'Stock.StockOrder.Supplier.code',
                    class: 'grey lighten-3 pa-1',
                    sortable: true
                },
                {
                    id: 13,
                    text: this.$t('message.container'),
                    value: 'Stock.StockOrder.container',
                    class: 'grey lighten-3 pa-1',
                    sortable: true
                },
                {
                    id: 14,
                    text: this.$t('message.stockInDate'),
                    value: 'Stock.StockOrder.stockindate',
                    class: 'grey lighten-3 pa-1',
                    sortable: true
                }
            ]
        },
        someSalesOrderData () {
            let data = {
                SalesOrder: {
                    id: this.SalesOrder__id,
                }
            }
            return data
        }
    },
    methods: {
        ...mapActions('salesorderitem',{
            addOtherChargesItemRow: 'addOtherChargesItemRow',
            addSalesOrderItemRows: 'addSalesOrderItemRows',
            deleteSalesOrderItem: 'deleteSalesOrderItem',
            resetSalesOrderItems: 'resetSalesOrderItems',
            updateTotals: 'updateTotals'
        }),
        ...mapActions('certification', {
            getAllCertifications: 'getAllCertifications'
        }),
        ...mapActions('currency', {
            getAllCurrencies: 'getAllCurrencies'
        }),
        ...mapActions('grade', {
            getAllGrades: 'getAllGrades'
        }),
        ...mapActions('productgroup', {
            getAllProductGroups: 'getAllProductGroups'
        }),
        ...mapMutations('salesorderitem',{
            deleteSalesOrderItemRow: 'deleteSalesOrderItemRow',
        }),
        ...mapActions('species', {
            getAllSpecies: 'getAllSpecies'
        }),
        addBundlesToSalesOrder () {
            this.loading.addBundle = true
            let payload = {
                bundles: this.allSelectedBundles,
                prefix: this.statePrefix,
                salesOrder: this.someSalesOrderData
            }
            this.addSalesOrderItemRows( payload )
                .then(() => {
                    this.updateTotals( this.statePrefix )
                        .then( () => {
                            this.loading.addBundle = false
                        })
                        .catch(() => {
                            this.loading.addBundle = false
                        })
                })
                .catch(() => {
                    this.loading.addBundle = false
                })
        },
        checkIfSelected (stockId) {
            let selected = false
            this.SalesOrderItems.forEach(item => {
                if(item.Stock.id == stockId) selected = true
            })
            return selected
        },
        currency (currency_id) {
            let code = this.allCurrencies.find((currency) => currency.Currency.id == currency_id)?.Currency?.code
            return code !== undefined ? code : ''
        },
        async deleteItem (itemIndex, itemId) {
            if (await this.$root.$confirm(this.$t('message.delete'), this.$t('message.confirmations.continueDeleteItemAction'), {color: 'red'})) {
                let promise = new Promise((resolve, reject) => {
                    let payload = {
                        index: itemIndex,
                        prefix: this.statePrefix
                    }
                    this.deleteSalesOrderItemRow(payload)
                    resolve('ok')
                });
                promise.then(() => {
                    if(itemId != null){
                        this.deleteSalesOrderItem({
                            salesOrderId: this.SalesOrder__id,
                            itemId: itemId}
                        )
                            .then((response)=>{
                                this.updateTotals( this.statePrefix )
                            })
                    } else {
                        this.updateTotals( this.statePrefix )
                    }
                })
            }
        },
        filterResults () {
            this.loading.filterResults = true
            this.tableOptions.page = 1
            let _this = this
            setTimeout(function(){
                _this.loading.filterResults = false
            },700)
        },
        findCurrencyPrecision (val) {
            let currency = this.allCurrencies.find(c => c.Currency.id == val)
            return currency?.Currency?.formatPrecision
        },
        formatDate,
        formatSize (item) {
            let size = [];
            if(item.Stock.thick?.length > 0) size.push(item.Stock.thick + (item.Stock.Thickness.uofm?.length > 0 ? " " + item.Stock.Thickness.uofm : ''))
            if(item.Stock.width?.length > 0) size.push(item.Stock.width + (item.Stock.Width.uofm?.length > 0 ? " " + item.Stock.Width.uofm : ''))
            if(item.Stock.length?.length > 0) size.push(item.Stock.length + (item.Stock.Length.uofm?.length > 0 ? " " + item.Stock.Length.uofm : ''))
            return size.join(' x ')
        },
        formatThisNumber (value,format) {
            return numberFormat(value,format)
        },
        getWarehouseStock () {
            let office = this.offices.find( o => o.Office.id == this.SalesOrder__office_id)
            return new Promise ((resolve, reject) => {
                this.loading.warehouseStock = true
                api.get('/stocks',{
                        params: {
                            conditions: [
                                {
                                    field: 'stockorders.stock_country_id',
                                    value: office.Office.country_id
                                },
                                {
                                    field: 'stocks.stockstate_id',
                                    value: 3
                                }
                            ],
                            order: [
                                {
                                    field: 'species.title',
                                    direction: 'ASC'
                                },
                                {
                                    field: 'stocks.bundleno',
                                    direction: 'ASC'
                                }
                            ]
                        }
                    })
                    .then( response => {
                        this.WarehouseStocks = response.data.data
                        this.tableOptions.page = 1
                        resolve('done')
                    })
                    .catch( error => {
                        reject(error)
                    })
                    .finally(() => {
                        this.loading.warehouseStock = false
                    })
            })
        },
        openSelectStockDialog () {
            this.selectStockDialog = true
            this.allSelectedBundles = []
            this.getWarehouseStock()
        },
        async resetItems () {
            if (await this.$root.$confirm(this.$t('message.resetSalesOrderItems'), this.$t('message.confirmations.continueSalesOrderItemsReset'), {color: 'orange'})) {
                this.resettingSalesOrderItems = true
                this.resetSalesOrderItems(this.statePrefix).then(() => {
                    this.resettingSalesOrderItems = false
                })
            }
        },
        uofmPrecision (uofm) {
            if(uofm) {
                if(isNaN(uofm)) {
                    return this.uofmQty.find((u) => u.Measurement.title == uofm.trim())?.Measurement?.formatPrecision
                } else {
                    return this.uofmQty.find((u) => u.Measurement.id == uofm)?.Measurement?.formatPrecision
                }
            } else {
                return '0,0.00'
            }
        },
        updateSalesOrderItemRowHandle (idx,field,value) {
            switch(field) {
                case 'Stock.soldmeasurement_id':
                    var payload = {
                        index: idx,
                        field: field,
                        prefix: this.statePrefix,
                        value: value,
                    }
                    //assign Soldmeasurement property in Stock
                    let measurement = this.uofmQty.filter(uofm => uofm.Measurement.id === value)[0];
                    if(measurement != null) {
                        this.$store.commit('salesorderitem/updateSalesOrderItems', {
                            index: idx,
                            field: 'Soldmeasurement.id',
                            prefix: this.statePrefix,
                            value: measurement.Measurement.id != null ? measurement.Measurement.id : null
                        })
                        this.$store.commit('salesorderitem/updateSalesOrderItems', {
                            index: idx,
                            field: 'Soldmeasurement.title',
                            prefix: this.statePrefix,
                            value: measurement.Measurement.title != null ? measurement.Measurement.title : null
                        })
                    }
                    break;
                case 'Stock.species_id':
                    var payload = {
                        index: idx,
                        field: field,
                        prefix: this.statePrefix,
                        value: value?.Species?.id
                    }
                    // assign Species property in Stock
                    this.$store.commit('salesorderitem/updateSalesOrderItems', {
                        index: idx,
                        field: 'Species.id',
                        prefix: this.statePrefix,
                        value: value?.Species?.id
                    })
                    this.$store.commit('salesorderitem/updateSalesOrderItems', {
                        index: idx,
                        field: 'Species.title',
                        prefix: this.statePrefix,
                        value: value?.Species?.title
                    })
                    break
                case 'Stock.grade_id':
                    var payload = {
                        index: idx,
                        field: field,
                        prefix: this.statePrefix,
                        value: value?.Grade?.id
                    }
                    // assign Grade property in Stock
                    this.$store.commit('salesorderitem/updateSalesOrderItems', {
                        index: idx,
                        field: 'Grade.id',
                        prefix: this.statePrefix,
                        value: value?.Grade?.id
                    })
                    this.$store.commit('salesorderitem/updateSalesOrderItems', {
                        index: idx,
                        field: 'Grade.title',
                        prefix: this.statePrefix,
                        value: value?.Grade?.title
                    })
                    break
                default:
                    var payload = {
                        index: idx,
                        field: field,
                        prefix: this.statePrefix,
                        value: value,
                    }
            }
            let promise = new Promise((resolve, reject) => {
                this.$store.commit('salesorderitem/updateSalesOrderItems', payload)
                resolve('ok')
            });
            promise.then(()=>{
                this.updateTotals(this.statePrefix)
            })
        }
    },
    watch: {},
    created () {
        if(this.SalesOrderItems.length > 20){
            this.disablePagination = false
            this.itemsPerPage = 10
        }
        if(this.allSpecies.length == 0) this.getAllSpecies()
        if(this.allGrades.length == 0) this.getAllGrades()
        if(this.allCertifications.length == 0) this.getAllCertifications()
        if(this.allProductGroups.length == 0) this.getAllProductGroups()
        if(this.allCurrencies.length == 0) this.getAllCurrencies()
        if(this.updateMode == false) {
            this.getWarehouseStock()
        }
    },
    mounted() {
        this.$store.watch(
            function (state) {
                return state.appic.salesorderitem.update.SalesOrderItems
            },
            () => {
                this.updatedSalesOrderUnsaved = true
            },
            {deep: true}
        )
        this.$store.watch(
            function (state) {
                return state.appic.salesorderitem.current.SalesOrderItems
            },
            () => {
                this.newSalesOrderUnsaved = true
            },
            {deep: true}
        )
    }
}
</script>

<style>
.bg-lightgrey {
    background-color: #F5F5F5;
}
.v-text-field__slot input {
    text-align: right;
}
.custom.v-text-field {
    font-size: 0.75rem !important;
}
.custom.v-text-field>.v-input__control>.v-input__slot:before {
    border-style: none;
}
.custom.v-text-field>.v-input__control>.v-input__slot:after {
    border-style: none;
}
.v-data-table td {
    padding: 0 5px;
}
.font-xs {
    font-size: 0.65rem;
}
.custom .v-icon {
    font-size: 18px !important;
}
.truncate {
    width: 100px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
.v-data-table th {
    white-space: nowrap;
}
.v-data-table tbody td {
    vertical-align: top !important;
    padding-left: 5px !important;
    padding-right: 5px !important;
    height: 40px !important;
    font-size: 0.75rem !important;
}
.row-reverse { flex-direction: row-reverse; }
.force-text-left {
    text-align: left !important;
}
.width-18-pct {
    width: 18%;
}
.v-data-table th.force-text-right {
    text-align: right !important;
}
</style>